var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "forgot-password",
      class: { "expiry-forgot-password": _vm.isExpiry }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _vm.i18n.passwordHeading
        ? _c(
            "h3",
            {
              staticClass: "mb-xs-4 email-heading",
              class: {
                "mt-xs-5": !_vm.isExpiry,
                "mt-sm-5 mt-xs-0": _vm.isExpiry
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.i18n.passwordHeading) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.resetButtonClicked,
              expression: "!resetButtonClicked"
            }
          ],
          class: { "expiry-forgot-password-form": _vm.isExpiry },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.resetPassword($event)
            }
          }
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-xs-0 email-description",
              class: { "error-msg": _vm.isExpiry },
              attrs: { role: "text" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.passwordDescription1) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "mb-xs-4 email-description",
              class: { "error-msg": _vm.isExpiry },
              attrs: { role: "text" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.i18n.passwordDescription2) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _vm.globals.isB2C()
            ? _c(
                "p",
                {
                  staticClass: "mt-xs-4 mb-xs-4 social-description",
                  attrs: { role: "text" }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.i18n.passwordSocialDescription) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-xs-0" }, [
            _c("label", { attrs: { "aria-hidden": _vm.isDeviceAndroid() } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.i18n.emailLabel) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emailAddress,
                  expression: "emailAddress"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true, regex: /^[^+]*$/, email: true },
                  expression: "{ required: true, regex: /^[^+]*$/, email: true}"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("email") },
              attrs: {
                type: "text",
                maxlength: "80",
                name: "email",
                "aria-describedby": "forgot_email_msg",
                "aria-label": _vm.i18n.emailLabel
              },
              domProps: { value: _vm.emailAddress },
              on: {
                focus: _vm.emailFocusIn,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.emailAddress = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "mt-xs-2",
                attrs: { "aria-hidden": !_vm.errors.has("email") }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "error-msg",
                    attrs: { id: "forgot_email_msg" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("email")) +
                        "\n                "
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.passwordServerError
            ? _c("div", { staticClass: "mt-xs-2" }, [
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.passwordServerError) +
                      "\n            "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-xs-2 px-xs-0 col-xs-12 d-flex",
              class: { "link-expired": !_vm.isExpiry }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "mt-xs-5 reset-password btn modal-btn-primary-medium form-blue-button",
                  class: { "modal-btn-xs-sticky": !_vm.isExpiry },
                  attrs: { type: "submit", disabled: _vm.errors.any() }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.i18n.passwordButtonValue) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.resetButtonClicked,
              expression: "resetButtonClicked"
            }
          ],
          staticClass: "success-msg"
        },
        [
          _c("div", { staticClass: "pb-xs-3 response-label" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.forgotPasswordLabel) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "response-description" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.forgotPasswordStatus) + "\n        "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cc67694c", { render: render, staticRenderFns: staticRenderFns })
  }
}