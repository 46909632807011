var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-list-details row" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "shareItemModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.listDetails.shareListHeading
          }
        },
        [
          _c("vx-share-list", {
            attrs: {
              slot: "component",
              wishlistId: _vm.wishlistUid,
              listId: _vm.listId,
              i18n: _vm.i18n
            },
            on: {
              "share-list-success": _vm.onShareListSuccess,
              "share-list-error": _vm.onShareListError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "shareListPdfModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.listDetails.shareListHeading
          }
        },
        [
          _c("vx-share-item", {
            attrs: {
              slot: "component",
              wishlistId: _vm.wishlistUid,
              listId: _vm.listId,
              i18n: _vm.i18n.shareList,
              "is-list": "",
              "certifications-name": _vm.certificationsName
            },
            on: {
              "share-item-success": _vm.onShareListPdfSuccess,
              "share-item-error": _vm.onShareListPdfError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "downloadProductExcelModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.listDetails.downloadProductExcelHeading
          }
        },
        [
          _c("vx-download-product-info", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              noProducts: _vm.noProducts
            },
            on: {
              onExcelDownloadInit: function($event) {
                _vm.downloadExcel($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addCustomAttributeModal",
          attrs: {
            size: "medium",
            heading: _vm.i18n.listDetails.addCustomAttributeHeading
          }
        },
        [
          _c("vx-add-custom-attribute", {
            attrs: {
              slot: "component",
              attributeList: _vm.attributeList,
              i18n: _vm.i18n.addCustomAttribute
            },
            on: {
              onCustomAttributeInit: function($event) {
                _vm.onCustomAttributeInit($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "deleteListModal",
          attrs: {
            size: "extra-small",
            heading: _vm.i18n.listDetails.deleteListHeading
          }
        },
        [
          _c("vx-delete-list", {
            attrs: {
              slot: "component",
              selectedListsName: _vm.selectedListNames,
              selectedLists: _vm.selectedLists,
              i18n: _vm.i18n
            },
            on: {
              "close-delete-success": _vm.deleteModalSuccess,
              "close-delete-error": _vm.deleteModalError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "downloadImagePdfModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.listDetails.downloadImagePdfHeading
          }
        },
        [
          _c("vx-download-info", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n,
              colorCodes: _vm.colorCodes,
              productEntries: _vm.productEntries,
              "is-image-download-enabled":
                _vm.globals.siteConfig.downloadListImage,
              "is-pdf-download-enabled": _vm.globals.siteConfig.downloadListPdf,
              certificationsName: _vm.certificationsName
            },
            on: {
              onImageDownloadInit: function($event) {
                _vm.onImageDownloadInit($event)
              },
              onPdfDownloadInit: function($event) {
                _vm.onPdfDownloadInit($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "editListModal",
          attrs: {
            size: "extra-small",
            heading: _vm.i18n.listDetails.editListNameHeading
          }
        },
        [
          _c("vx-edit-list-name", {
            attrs: {
              slot: "component",
              listName: _vm.listName,
              listId: _vm.listId,
              i18n: _vm.i18n
            },
            on: {
              "close-edit-success": _vm.editListNameSuccess,
              "close-edit-error": _vm.editListNameError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "installationDetailsModal",
          attrs: {
            size: "small",
            heading: _vm.i18n.listDetails.installationDetailsHeading
          }
        },
        [
          _c("p", {
            attrs: { slot: "component" },
            domProps: { innerHTML: _vm._s(_vm.message) },
            slot: "component"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "addGiftModal",
          attrs: { size: "small", heading: _vm.i18n.listDetails.giftHeading }
        },
        [
          _c("p", {
            attrs: { slot: "component" },
            domProps: { innerHTML: _vm._s(_vm.message) },
            slot: "component"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "notifyMeModal",
          attrs: {
            size: "extra-small",
            heading: _vm.i18n.listDetails.notifyMeHeading
          }
        },
        [
          _c("vx-notify-me", {
            attrs: {
              slot: "component",
              title: _vm.i18n.listDetails.notifyMeContent,
              productCode: _vm.notifyCode,
              i18n: _vm.i18n.listDetails
            },
            on: {
              "notify-me-success": _vm.onNotifyMeSuccess,
              "notify-me-error": _vm.onNotifyMeError
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showGuestUserAddListMessage
        ? _c(
            "div",
            { staticClass: "col-xs-12 mt-xs-5 guest-add-list-message" },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.i18n.listDetails.showGuestUserAddListMessage) +
                  "\n  "
              )
            ]
          )
        : _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "row d-flex align-items-center justify-content-between favorites"
              },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "col-xs-12 lisSharedListist-details-name word-wrap-all h3"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.listId.substring(0, 1).toUpperCase() +
                            _vm.listId.substring(1)
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.isFavorites && !_vm.noProducts
                  ? _c("span", {
                      staticClass: "icon-share mr-lg-3 mr-md-3 mr-sm-3 mr-xs-3",
                      attrs: {
                        disabled: _vm.noProducts,
                        role: "button",
                        tabindex: "0",
                        "aria-label": "Share list"
                      },
                      on: {
                        click: function($event) {
                          _vm.handleShareList($event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.handleShareList($event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFilterRequired
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchQuery,
                          expression: "searchQuery"
                        }
                      ],
                      staticClass: "list-filter",
                      attrs: {
                        placeholder: _vm.i18n.listDetails.listFilterPlaceholder,
                        type: "text"
                      },
                      domProps: { value: _vm.searchQuery },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchQuery = $event.target.value
                        }
                      }
                    })
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", [
              !_vm.isFavorites
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-lg-3 mb-lg-4 pb-xs-4 pb-md-0 col-xs-12 d-flex align-items-center justify-content-between list-subtotal-container"
                      },
                      [
                        _c("div", [
                          !_vm.isSharedList && !_vm.isSharedCart
                            ? _c("span", {
                                staticClass: "icon-edit mr-xs-4",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconEditTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleEditList($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleEditList($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSharable
                            ? _c("span", {
                                staticClass: "icon-share mr-xs-4",
                                attrs: {
                                  disabled: _vm.noProducts,
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconShareTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleShareList($event, "list")
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleShareList($event, "list")
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.globals.siteConfig.addToListBySku &&
                          _vm.isAddProduct
                            ? _c("span", {
                                staticClass: "icon-plus mr-xs-4",
                                class: {
                                  "icon-plus-active": _vm.canAddProductsToList
                                },
                                attrs: {
                                  "aria-label":
                                    _vm.i18n.listDetails
                                      .iconAddProductsToListTitle,
                                  tabindex: "0",
                                  role: "button",
                                  "data-toggle": "tooltip"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.canAddProductsToList = !_vm.canAddProductsToList
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.canAddProductsToList = !_vm.canAddProductsToList
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.globals.siteConfig.downloadListPdf ||
                            _vm.globals.siteConfig.downloadListImage) &&
                          _vm.isDownloadable
                            ? _c("span", {
                                staticClass: "icon-download mr-xs-4",
                                attrs: {
                                  tabindex: "0",
                                  "data-toggle": "tooltip",
                                  disabled: _vm.noProducts,
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconDownloadTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleDownloadList($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleDownloadList($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.globals.siteConfig.downloadPrdListInfo &&
                          _vm.isExcelDownloadable
                            ? _c("span", {
                                staticClass: "icon-download-info-new mr-xs-4",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconDownloadExcelTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleDownloadProductInfo($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleDownloadProductInfo($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.globals.siteConfig.addCustomAttrs
                            ? _c("span", {
                                staticClass:
                                  "icon-add-custom-attributes mr-xs-4",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  disabled: _vm.noProducts,
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails
                                      .iconAddCustomAttributeTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleAddCustomAttribute($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleAddCustomAttribute($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isSharedList &&
                          !_vm.isSharedCart &&
                          !_vm.globals.loggedIn &&
                          !_vm.noProducts
                            ? _c("span", {
                                staticClass: "icon-save mr-xs-4",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconSaveTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleSaveList($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleSaveList($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isSharedList &&
                          !_vm.isSharedCart &&
                          _vm.globals.getIsLoggedIn()
                            ? _c("span", {
                                staticClass: "icon-trash",
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label":
                                    _vm.i18n.listDetails.iconDeleteTitle
                                },
                                on: {
                                  click: function($event) {
                                    _vm.handleDeleteList($event)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleDeleteList($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canAddProductsToList
                            ? _c(
                                "div",
                                [
                                  _c("vx-add-product-to-list", {
                                    attrs: { i18n: _vm.i18n.listDetails },
                                    on: {
                                      addProductsToList: function($event) {
                                        _vm.handleAddProductsToList($event)
                                      },
                                      closeAddProductToList: function($event) {
                                        _vm.closeAddProductPopover()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        !_vm.noProducts
                          ? _c("div", { staticClass: "subtotal-section" }, [
                              _c(
                                "span",
                                { staticClass: "list-subtotal-label" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.i18n.listDetails.listSubtotal)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "strong",
                                { staticClass: "list-subtotal-label" },
                                [_vm._v(_vm._s(_vm.subtotal))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.noProducts
              ? _c("h5", { staticClass: "no-products" }, [
                  _vm._v(_vm._s(_vm.noListMessage))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isFavorites
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 list-items-container" },
                      _vm._l(_vm.filteredProductEntries, function(
                        productEntry,
                        key
                      ) {
                        return _c(
                          "vx-product-tile",
                          { key: productEntry },
                          [
                            !_vm.isSharedList && !_vm.isSharedCart
                              ? _c("template", { slot: "top-right" }, [
                                  _c(
                                    "span",
                                    { staticClass: " col-xs-2 px-xs-0" },
                                    [
                                      _c("span", {
                                        staticClass: "cart-icons icon-trash",
                                        attrs: {
                                          "aria-label":
                                            _vm.i18n.listDetails
                                              .iconDeleteTitle,
                                          tabindex: "0",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.deleteCartItem(
                                              productEntry.product.code
                                            )
                                          },
                                          keyup: function($event) {
                                            if (
                                              !("button" in $event) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter-space",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.deleteCartItem(
                                              productEntry.product.code
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("template", { slot: "promo-section" }, [
                              productEntry.product.materialStatus ===
                              _vm.ProductAvailability.COMING_SOON
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "coming-soon-status hidden-lg hidden-sm hidden-md"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.i18n.listDetails.comingSoonLabel
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            productEntry.product.images &&
                            productEntry.product.images.length
                              ? _c("template", { slot: "image-slot" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.globals.getNavigationUrl(
                                            "empty"
                                          ) + productEntry.product.url
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.globals.getThumbnailImageUrl(
                                            productEntry.product.images
                                          ),
                                          alt:
                                            productEntry.product.images[2]
                                              .altText
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  productEntry.product.materialStatus ===
                                  _vm.ProductAvailability.COMING_SOON
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "coming-soon-status hidden-xs "
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.i18n.listDetails
                                                  .comingSoonLabel
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !productEntry.product.images ||
                            (productEntry.product.images &&
                              !productEntry.product.images.length)
                              ? _c("template", { slot: "image-slot" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.globals.getNavigationUrl(
                                            "empty"
                                          ) + productEntry.product.url
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/no_image.svg",
                                          alt: "no image"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  productEntry.product.materialStatus ===
                                  _vm.ProductAvailability.COMING_SOON
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "coming-soon-status hidden-xs"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.i18n.listDetails
                                                  .comingSoonLabel
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "product-icons-slot" },
                              [
                                productEntry.product.productIcons
                                  ? _c("vx-product-icons", {
                                      attrs: {
                                        i18n: _vm.i18n.productIconsTitle,
                                        "product-icons":
                                          productEntry.product.productIcons
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                productEntry.product.installationProduct
                                  ? _c("div", [
                                      productEntry.product.installationProduct
                                        .installable
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.openInstallationDetailsModal(
                                                    productEntry.product
                                                      .installationProduct
                                                      .installableMessage
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails
                                                    .installationLink
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                productEntry.product.giftWrapProduct
                                  ? _c("div", [
                                      productEntry.product.giftWrapProduct
                                        .giftWrapped
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.openGiftModal(
                                                    productEntry.product
                                                      .giftWrapProduct
                                                      .giftMessage
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails.giftLink
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "details" },
                              [
                                productEntry &&
                                productEntry.product &&
                                productEntry.product.name
                                  ? [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "product-title mb-xs-2 pr-xs-5"
                                        },
                                        [
                                          _c("a", {
                                            attrs: {
                                              href:
                                                _vm.globals.getNavigationUrl(
                                                  "empty"
                                                ) + productEntry.product.url
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                productEntry.product.name
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(
                                      _vm._s(_vm.i18n.listDetails.itemId) +
                                        " : " +
                                        _vm._s(productEntry.product.code)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "item-id",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.i18n.listDetails.itemId) +
                                          "\n                  "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(productEntry.product.code)
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  productEntry.product.cmirCode
                                    ? _c("span", { staticClass: "separator" }, [
                                        _vm._v(" | ")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  productEntry.product.cmirCode
                                    ? _c("span", { staticClass: "cmir-code" }, [
                                        _vm._v(
                                          _vm._s(_vm.i18n.listDetails.cmir) +
                                            "\n                  "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              productEntry.product.cmirCode
                                            ) + "\n                  "
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                productEntry.product.price &&
                                _vm.globals.siteConfig.isProductPriceEnabled &&
                                !productEntry.product.notPurchasable
                                  ? _c(
                                      "p",
                                      { staticClass: "product-price mb-xs-3" },
                                      [
                                        productEntry.product.price
                                          .formattedValue
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "product-current-price"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [
                                                    _vm._v(
                                                      "Current price\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(
                                                    productEntry.product.price
                                                      .formattedValue
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        productEntry.product.weblistPrice &&
                                        _vm.globals.siteConfig
                                          .isSlashedPriceEnabled
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "product-old-price"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Slashed price")]
                                                ),
                                                _vm._v(" "),
                                                _c("strike", [
                                                  _vm._v(
                                                    _vm._s(
                                                      productEntry.product
                                                        .weblistPrice
                                                        .formattedValue
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.globals.isB2B()
                                  ? _c("div", [
                                      productEntry.product &&
                                      (productEntry.product.materialStatus ===
                                        "OBSOLETE" ||
                                        productEntry.product.materialStatus ===
                                          "PREDISCONTINUED") &&
                                      productEntry.product
                                        .replacementProductCode &&
                                      productEntry.product
                                        .replacementProductCode !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "error-text" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .discontinued
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .replacedBy
                                                  ) +
                                                  "\n                  " +
                                                  _vm._s(
                                                    productEntry.product
                                                      .replacementProductCode
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      productEntry.product &&
                                      (productEntry.product.materialStatus ===
                                        "OBSOLETE" ||
                                        productEntry.product.materialStatus ===
                                          "PREDISCONTINUED") &&
                                      !productEntry.product
                                        .replacementProductCode
                                        ? _c(
                                            "p",
                                            { staticClass: "error-text" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .discontinued
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      productEntry.product.materialStatus ===
                                      _vm.ProductAvailability.COMING_SOON
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-stock-status"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .availableSoon
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      productEntry.product.notPurchasable
                                        ? _c(
                                            "p",
                                            { staticClass: "error-text" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .notPurchasable
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                productEntry &&
                                productEntry.product &&
                                productEntry.product.materialStatus !==
                                  "OBSOLETE" &&
                                productEntry.product.materialStatus !==
                                  "PREDISCONTINUED" &&
                                productEntry.product.materialStatus !==
                                  _vm.ProductAvailability.COMING_SOON &&
                                productEntry.product.stock &&
                                _vm.globals.siteConfig.showProductStatus
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "stock-status" },
                                        [
                                          productEntry.product.stock
                                            .stockLevel &&
                                          (productEntry.product.stock
                                            .stockLevelStatus ===
                                            _vm.ProductAvailability.LOW_STOCK ||
                                            productEntry.product.stock
                                              .stockLevelStatus.code ===
                                              _vm.ProductAvailability
                                                .LOW_STOCK) &&
                                          (_vm.globals.siteConfig
                                            .showInStockMsg ||
                                            _vm.globals.siteConfig
                                              .showLowInventoryMessage)
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock-status mb-xs-3",
                                                  class: {
                                                    "text-danger":
                                                      _vm.globals.siteConfig
                                                        .showLowInventoryMessage
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.globals.siteConfig
                                                          .showLowInventoryMessage
                                                          ? _vm.i18n.listDetails
                                                              .lowInventoryMessage
                                                          : _vm.i18n.listDetails
                                                              .inStock
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : productEntry.product.stock
                                                .nextDeliveryDate
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock mb-xs-3 error-text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        productEntry.product
                                                          .stock
                                                          .nextDeliveryDate
                                                      )
                                                  )
                                                ]
                                              )
                                            : (productEntry.product.stock
                                                .stockLevelStatus ===
                                                _vm.ProductAvailability
                                                  .IN_STOCK ||
                                                productEntry.product.stock
                                                  .stockLevelStatus.code ===
                                                  _vm.ProductAvailability
                                                    .IN_STOCK) &&
                                              _vm.globals.siteConfig
                                                .showInStockMsg
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock-status mb-xs-3 "
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .inStock
                                                      )
                                                  )
                                                ]
                                              )
                                            : productEntry.product.stock
                                                .stockLevelStatus !==
                                                _vm.ProductAvailability
                                                  .IN_STOCK ||
                                              (productEntry.product.stock
                                                .stockLevelStatus.code !==
                                                _vm.ProductAvailability
                                                  .IN_STOCK &&
                                                productEntry.product.stock
                                                  .stockLevelStatus.code !==
                                                  undefined &&
                                                productEntry.product.stock
                                                  .stockLevelStatus.code !==
                                                  _vm.ProductAvailability
                                                    .LOW_STOCK)
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-stock mb-xs-3 error-text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .outOfStock
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.globals.siteConfig.isQuantitySelectorEnabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pr-lg-5 mr-lg-3 d-flex quantity-section"
                                      },
                                      [
                                        !_vm.isSharedList && !_vm.isSharedCart
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "stepper-wrapper"
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "quantity-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .quantityLabel
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("vx-stepper-control", {
                                                  attrs: {
                                                    "max-value": _vm.maxOrderQuantity(
                                                      productEntry.product
                                                        .maxOrderQuantity
                                                    ),
                                                    value:
                                                      productEntry.quantity,
                                                    "min-value": _vm.minOrderQuantity(
                                                      productEntry.product
                                                        .minOrderQuantity
                                                    ),
                                                    "stock-level": _vm.setStockLevel(
                                                      productEntry.product.stock
                                                        .stockLevel
                                                    )
                                                  },
                                                  on: {
                                                    currentCount: function(
                                                      $event
                                                    ) {
                                                      _vm.getQuantity(
                                                        productEntry.product
                                                          .price.formattedValue,
                                                        productEntry.product
                                                          .code,
                                                        $event
                                                      )
                                                    },
                                                    maxValueUpdated:
                                                      _vm.maxValueUpdated,
                                                    minValueUpdated:
                                                      _vm.minValueUpdated,
                                                    stockLevelUpdated:
                                                      _vm.stockLevelUpdated,
                                                    disableAddToCart:
                                                      _vm.disableAddToCart
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isSharedList || _vm.isSharedCart
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "stepper-wrapper"
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "quantity-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .quantityLabel
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("vx-stepper-control", {
                                                  attrs: {
                                                    "max-value": _vm.maxOrderQuantity(
                                                      productEntry.product
                                                        .maxOrderQuantity
                                                    ),
                                                    value:
                                                      productEntry.quantity,
                                                    "min-value": _vm.minOrderQuantity(
                                                      productEntry.product
                                                        .minOrderQuantity
                                                    ),
                                                    "stock-level": _vm.setStockLevel(
                                                      productEntry.product.stock
                                                        .stockLevel
                                                    )
                                                  },
                                                  on: {
                                                    currentCount: function(
                                                      $event
                                                    ) {
                                                      _vm.getQuantity(
                                                        productEntry.product
                                                          .price.formattedValue,
                                                        productEntry.product
                                                          .code,
                                                        $event
                                                      )
                                                    },
                                                    maxValueUpdated:
                                                      _vm.maxValueUpdated,
                                                    minValueUpdated:
                                                      _vm.minValueUpdated,
                                                    stockLevelUpdated:
                                                      _vm.stockLevelUpdated,
                                                    disableAddToCart:
                                                      _vm.disableAddToCart
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.globals.siteConfig.isProductPriceEnabled
                              ? _c("template", { slot: "right-section-slot" }, [
                                  productEntry.totalPrice &&
                                  !productEntry.product.notPurchasable
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "d-flex align-items-end"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-total-price",
                                              attrs: {
                                                role: "status",
                                                "aria-label":
                                                  "Total price" +
                                                  productEntry.totalPrice
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    productEntry.totalPrice
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-xs-0 py-xs-2 px-sm-0 pl-md-4 d-flex align-items-end"
                                    },
                                    [
                                      (productEntry.product &&
                                        productEntry.product.stock &&
                                        productEntry.product.stock
                                          .stockLevelStatus &&
                                        productEntry.product.stock
                                          .stockLevelStatus ===
                                          _vm.ProductAvailability
                                            .OUT_OF_STOCK) ||
                                      productEntry.product.materialStatus ===
                                        _vm.ProductAvailability.COMING_SOON
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-tertiary notify",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  _vm.notifyMe(
                                                    productEntry.product
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails.notifyMe
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn button-color text-trans add-to-cart-btn pull-right",
                                              attrs: {
                                                disabled:
                                                  _vm.addToCartDisabled ||
                                                  !productEntry.product
                                                    .soldIndividually ||
                                                  productEntry.product
                                                    .notPurchasable,
                                                "aria-label":
                                                  _vm.i18n.listDetails.addToCart
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.addToCart(
                                                    productEntry.quantity,
                                                    productEntry.product.code
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails.addToCart
                                                )
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customAttributes.length
                              ? _c(
                                  "template",
                                  { slot: "bottom-full-slot" },
                                  [
                                    _c(
                                      "vx-accordion",
                                      {
                                        attrs: {
                                          opened:
                                            _vm.activeContainerIndex === key
                                              ? true
                                              : false,
                                          isSlotBased: true,
                                          i18n: _vm.i18n.listDetails,
                                          accordionData: _vm.accordionData
                                        },
                                        on: {
                                          accordionStatus: function($event) {
                                            _vm.isAccordionOpen = $event
                                          }
                                        }
                                      },
                                      [
                                        _c("template", { slot: "component" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xs-12 accordionContent",
                                              class: "accordion-" + _vm.index
                                            },
                                            _vm._l(
                                              _vm.customAttributes,
                                              function(attribute, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-container form-group col-xs-12 col-sm-4"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: attribute.value
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            attribute.value
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        name: attribute.value,
                                                        maxlength: "20",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value:
                                                          productEntry[
                                                            attribute.label
                                                          ]
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.saveCustomAttributeValue(
                                                            $event,
                                                            productEntry.product,
                                                            attribute,
                                                            key,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              }
                                            )
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      })
                    )
                  ])
                ])
              : !_vm.isFavorites
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.categoryGroups, function(categoryGroup) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center"
                          },
                          [
                            categoryGroup.value.wishlistEntryGroup.length
                              ? _c("h3", [_vm._v(_vm._s(categoryGroup.key))])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.handleRemoveCategoryClick(
                                      $event,
                                      categoryGroup
                                    )
                                  },
                                  keyup: function($event) {
                                    if (
                                      !("button" in $event) &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter-space",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    _vm.handleRemoveCategoryClick(
                                      $event,
                                      categoryGroup
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.listDetails.removeCategory)
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 list-items-container" },
                            _vm._l(
                              categoryGroup.value.wishlistEntryGroup,
                              function(productEntry, key) {
                                return _c(
                                  "vx-product-tile",
                                  { key: productEntry },
                                  [
                                    !_vm.isSharedList && !_vm.isSharedCart
                                      ? _c("template", { slot: "top-right" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: " col-xs-2 px-xs-0"
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "cart-icons icon-trash",
                                                attrs: {
                                                  "aria-label":
                                                    _vm.i18n.listDetails
                                                      .iconDeleteTitle,
                                                  tabindex: "0",
                                                  role: "button"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.deleteCartItem(
                                                      productEntry.product.code
                                                    )
                                                  },
                                                  keyup: function($event) {
                                                    if (
                                                      !("button" in $event) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter-space",
                                                        undefined,
                                                        $event.key,
                                                        undefined
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    _vm.deleteCartItem(
                                                      productEntry.product.code
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("template", { slot: "promo-section" }, [
                                      productEntry.product.materialStatus ===
                                      _vm.ProductAvailability.COMING_SOON
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "coming-soon-status hidden-lg hidden-sm hidden-md"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.i18n.listDetails
                                                      .comingSoonLabel
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    productEntry.product.images
                                      ? _c("template", { slot: "image-slot" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.globals.getNavigationUrl(
                                                    "empty"
                                                  ) + productEntry.product.url
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.globals.getThumbnailImageUrl(
                                                    productEntry.product.images
                                                  ),
                                                  alt:
                                                    productEntry.product
                                                      .images[2].altText
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          productEntry.product
                                            .materialStatus ===
                                          _vm.ProductAvailability.COMING_SOON
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "coming-soon-status hidden-xs "
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .comingSoonLabel
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !productEntry.product.images
                                      ? _c("template", { slot: "image-slot" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.globals.getNavigationUrl(
                                                    "empty"
                                                  ) + productEntry.product.url
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.globals.assetsPath +
                                                    "images/no_image.svg",
                                                  alt: "no image"
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          productEntry.product
                                            .materialStatus ===
                                          _vm.ProductAvailability.COMING_SOON
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "coming-soon-status hidden-xs"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n.listDetails
                                                          .comingSoonLabel
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "product-icons-slot" },
                                      [
                                        productEntry.product.productIcons
                                          ? _c("vx-product-icons", {
                                              attrs: {
                                                i18n:
                                                  _vm.i18n.productIconsTitle,
                                                "product-icons":
                                                  productEntry.product
                                                    .productIcons
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        productEntry.product.installationProduct
                                          ? _c("div", [
                                              productEntry.product
                                                .installationProduct.installable
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.openInstallationDetailsModal(
                                                            productEntry.product
                                                              .installationProduct
                                                              .installableMessage
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n.listDetails
                                                            .installationLink
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        productEntry.product.giftWrapProduct
                                          ? _c("div", [
                                              productEntry.product
                                                .giftWrapProduct.giftWrapped
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.openGiftModal(
                                                            productEntry.product
                                                              .giftWrapProduct
                                                              .giftMessage
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n.listDetails
                                                            .giftLink
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "details" },
                                      [
                                        productEntry &&
                                        productEntry.product &&
                                        productEntry.product.name
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-title mb-xs-2 pr-xs-5"
                                                },
                                                [
                                                  _c("a", {
                                                    attrs: {
                                                      href:
                                                        _vm.globals.getNavigationUrl(
                                                          "empty"
                                                        ) +
                                                        productEntry.product.url
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        productEntry.product
                                                          .name
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("p", [
                                          _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails.itemId
                                                ) +
                                                  " : " +
                                                  _vm._s(
                                                    productEntry.product.code
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "item-id",
                                              attrs: { "aria-hidden": "true" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.listDetails.itemId
                                                ) + "\n                    "
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    productEntry.product.code
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          productEntry.product.cmirCode
                                            ? _c(
                                                "span",
                                                { staticClass: "separator" },
                                                [_vm._v(" | ")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          productEntry.product.cmirCode
                                            ? _c(
                                                "span",
                                                { staticClass: "cmir-code" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n.listDetails.cmir
                                                    ) + "\n                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        productEntry.product
                                                          .cmirCode
                                                      ) +
                                                        "\n                    "
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _vm.globals.siteConfig
                                          .enableDisplayAttributes &&
                                        productEntry.product.displayAttributes
                                          ? _c("div", {
                                              staticClass: "display-attributes",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.displayAttributes(
                                                    productEntry.product
                                                      .displayAttributes
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        productEntry.product.price &&
                                        _vm.globals.siteConfig
                                          .isProductPriceEnabled &&
                                        !productEntry.product.notPurchasable
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "product-price mb-xs-3"
                                              },
                                              [
                                                productEntry.product.price
                                                  .formattedValue
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "product-current-price"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sr-only"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Current price\n                    "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            productEntry.product
                                                              .price
                                                              .formattedValue
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productEntry.product
                                                  .weblistPrice &&
                                                _vm.globals.siteConfig
                                                  .isSlashedPriceEnabled
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "product-old-price"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sr-only"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Slashed price"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("strike", [
                                                          _vm._v(
                                                            _vm._s(
                                                              productEntry
                                                                .product
                                                                .weblistPrice
                                                                .formattedValue
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.globals.isB2B()
                                          ? _c("div", [
                                              productEntry.product &&
                                              (productEntry.product
                                                .materialStatus ===
                                                "OBSOLETE" ||
                                                productEntry.product
                                                  .materialStatus ===
                                                  "PREDISCONTINUED") &&
                                              productEntry.product
                                                .replacementProductCode &&
                                              productEntry.product
                                                .replacementProductCode !== ""
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "error-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.i18n.listDetails
                                                              .discontinued
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.i18n.listDetails
                                                              .replacedBy
                                                          ) +
                                                          "\n                    " +
                                                          _vm._s(
                                                            productEntry.product
                                                              .replacementProductCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              productEntry.product &&
                                              (productEntry.product
                                                .materialStatus ===
                                                "OBSOLETE" ||
                                                productEntry.product
                                                  .materialStatus ===
                                                  "PREDISCONTINUED") &&
                                              !productEntry.product
                                                .replacementProductCode
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "error-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.i18n.listDetails
                                                              .discontinued
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              productEntry.product
                                                .materialStatus ===
                                              _vm.ProductAvailability
                                                .COMING_SOON
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "product-stock-status"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.i18n.listDetails
                                                              .availableSoon
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              productEntry.product
                                                .notPurchasable
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "error-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.i18n.listDetails
                                                              .notPurchasable
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        productEntry &&
                                        productEntry.product &&
                                        productEntry.product.materialStatus !==
                                          "OBSOLETE" &&
                                        productEntry.product.materialStatus !==
                                          "PREDISCONTINUED" &&
                                        productEntry.product.materialStatus !==
                                          _vm.ProductAvailability.COMING_SOON &&
                                        productEntry.product.stock &&
                                        _vm.globals.siteConfig.showProductStatus
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "stock-status" },
                                                [
                                                  productEntry.product.stock
                                                    .stockLevel &&
                                                  (productEntry.product.stock
                                                    .stockLevelStatus ===
                                                    _vm.ProductAvailability
                                                      .LOW_STOCK ||
                                                    productEntry.product.stock
                                                      .stockLevelStatus.code ===
                                                      _vm.ProductAvailability
                                                        .LOW_STOCK) &&
                                                  (_vm.globals.siteConfig
                                                    .showInStockMsg ||
                                                    _vm.globals.siteConfig
                                                      .showLowInventoryMessage)
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-stock-status mb-xs-3",
                                                          class: {
                                                            "text-danger":
                                                              _vm.globals
                                                                .siteConfig
                                                                .showLowInventoryMessage
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.globals
                                                                  .siteConfig
                                                                  .showLowInventoryMessage
                                                                  ? _vm.i18n
                                                                      .listDetails
                                                                      .lowInventoryMessage
                                                                  : _vm.i18n
                                                                      .listDetails
                                                                      .inStock
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    : productEntry.product.stock
                                                        .nextDeliveryDate
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-stock mb-xs-3 error-text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                productEntry
                                                                  .product.stock
                                                                  .nextDeliveryDate
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    : (productEntry.product
                                                        .stock
                                                        .stockLevelStatus ===
                                                        _vm.ProductAvailability
                                                          .IN_STOCK ||
                                                        productEntry.product
                                                          .stock
                                                          .stockLevelStatus
                                                          .code ===
                                                          _vm
                                                            .ProductAvailability
                                                            .IN_STOCK) &&
                                                      _vm.globals.siteConfig
                                                        .showInStockMsg
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-stock-status mb-xs-3 "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .listDetails
                                                                  .inStock
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    : productEntry.product.stock
                                                        .stockLevelStatus !==
                                                        _vm.ProductAvailability
                                                          .IN_STOCK ||
                                                      (productEntry.product
                                                        .stock.stockLevelStatus
                                                        .code !==
                                                        _vm.ProductAvailability
                                                          .IN_STOCK &&
                                                        productEntry.product
                                                          .stock
                                                          .stockLevelStatus
                                                          .code !== undefined &&
                                                        productEntry.product
                                                          .stock
                                                          .stockLevelStatus
                                                          .code !==
                                                          _vm
                                                            .ProductAvailability
                                                            .LOW_STOCK)
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-stock mb-xs-3 error-text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .listDetails
                                                                  .outOfStock
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.globals.siteConfig
                                          .isQuantitySelectorEnabled
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pr-lg-5 mr-lg-3 d-flex quantity-section"
                                              },
                                              [
                                                !_vm.isSharedList &&
                                                !_vm.isSharedCart
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "stepper-wrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "quantity-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .listDetails
                                                                  .quantityLabel
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "vx-stepper-control",
                                                          {
                                                            attrs: {
                                                              "max-value": _vm.maxOrderQuantity(
                                                                productEntry
                                                                  .product
                                                                  .maxOrderQuantity
                                                              ),
                                                              value:
                                                                productEntry.quantity,
                                                              "min-value": _vm.minOrderQuantity(
                                                                productEntry
                                                                  .product
                                                                  .minOrderQuantity
                                                              ),
                                                              "stock-level": _vm.setStockLevel(
                                                                productEntry
                                                                  .product.stock
                                                                  .stockLevel
                                                              )
                                                            },
                                                            on: {
                                                              currentCount: function(
                                                                $event
                                                              ) {
                                                                _vm.getQuantity(
                                                                  productEntry
                                                                    .product
                                                                    .price
                                                                    .formattedValue,
                                                                  productEntry
                                                                    .product
                                                                    .code,
                                                                  $event
                                                                )
                                                              },
                                                              maxValueUpdated:
                                                                _vm.maxValueUpdated,
                                                              minValueUpdated:
                                                                _vm.minValueUpdated,
                                                              stockLevelUpdated:
                                                                _vm.stockLevelUpdated,
                                                              disableAddToCart:
                                                                _vm.disableAddToCart
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isSharedList ||
                                                _vm.isSharedCart
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "stepper-wrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "quantity-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .listDetails
                                                                  .quantityLabel
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "vx-stepper-control",
                                                          {
                                                            attrs: {
                                                              "max-value": _vm.maxOrderQuantity(
                                                                productEntry
                                                                  .product
                                                                  .maxOrderQuantity
                                                              ),
                                                              value:
                                                                productEntry.quantity,
                                                              "min-value": _vm.minOrderQuantity(
                                                                productEntry
                                                                  .product
                                                                  .minOrderQuantity
                                                              ),
                                                              "stock-level": _vm.setStockLevel(
                                                                productEntry
                                                                  .product.stock
                                                                  .stockLevel
                                                              )
                                                            },
                                                            on: {
                                                              currentCount: function(
                                                                $event
                                                              ) {
                                                                _vm.getQuantity(
                                                                  productEntry
                                                                    .product
                                                                    .price
                                                                    .formattedValue,
                                                                  productEntry
                                                                    .product
                                                                    .code,
                                                                  $event
                                                                )
                                                              },
                                                              maxValueUpdated:
                                                                _vm.maxValueUpdated,
                                                              minValueUpdated:
                                                                _vm.minValueUpdated,
                                                              stockLevelUpdated:
                                                                _vm.stockLevelUpdated,
                                                              disableAddToCart:
                                                                _vm.disableAddToCart
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.globals.siteConfig.isProductPriceEnabled
                                      ? _c(
                                          "template",
                                          { slot: "right-section-slot" },
                                          [
                                            productEntry.totalPrice &&
                                            !productEntry.product.notPurchasable
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-end"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-total-price",
                                                        attrs: {
                                                          role: "status",
                                                          "aria-label":
                                                            "Total price" +
                                                            productEntry.totalPrice
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              productEntry.totalPrice
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "px-xs-0 py-xs-2 px-sm-0 pl-md-4 d-flex align-items-end"
                                              },
                                              [
                                                _vm.verifyNotifyMeDisplay(
                                                  productEntry
                                                )
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-tertiary notify",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.notifyMe(
                                                              productEntry.product
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .listDetails
                                                                .notifyMe
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn button-color text-trans add-to-cart-btn pull-right",
                                                        class: {
                                                          disabled: _vm.verifyAddtocartDisable(
                                                            productEntry
                                                          )
                                                        },
                                                        attrs: {
                                                          disabled: _vm.verifyAddtocartDisable(
                                                            productEntry
                                                          ),
                                                          "aria-label":
                                                            _vm.i18n.listDetails
                                                              .addToCart
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.addToCart(
                                                              productEntry.quantity,
                                                              productEntry
                                                                .product.code
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .listDetails
                                                                .addToCart
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customAttributes.length
                                      ? _c(
                                          "template",
                                          { slot: "bottom-full-slot" },
                                          [
                                            _c(
                                              "vx-accordion",
                                              {
                                                attrs: {
                                                  opened:
                                                    _vm.activeContainerIndex ===
                                                    key
                                                      ? true
                                                      : false,
                                                  isSlotBased: true,
                                                  i18n: _vm.i18n.listDetails,
                                                  accordionData:
                                                    _vm.accordionData
                                                },
                                                on: {
                                                  accordionStatus: function(
                                                    $event
                                                  ) {
                                                    _vm.isAccordionOpen = $event
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "component" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-xs-12 accordionContent",
                                                        class:
                                                          "accordion-" +
                                                          _vm.index
                                                      },
                                                      _vm._l(
                                                        _vm.customAttributes,
                                                        function(
                                                          attribute,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-container form-group col-xs-12 col-sm-4"
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for:
                                                                      attribute.value
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      attribute.value
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  name:
                                                                    attribute.value,
                                                                  maxlength:
                                                                    "20",
                                                                  type: "text"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    productEntry[
                                                                      attribute
                                                                        .label
                                                                    ]
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.saveCustomAttributeValue(
                                                                      $event,
                                                                      productEntry.product,
                                                                      attribute,
                                                                      key
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }
                            )
                          )
                        ])
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.noProducts
              ? _c("div", [
                  !_vm.isFavorites &&
                  _vm.globals.siteConfig.showListSampleCart &&
                  _vm.isAddtoSampleEnabled
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-12 px-xs-0 py-xs-2 px-sm-3 px-sm-5 px-md-7"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn button-color text-trans pull-right add-list-to-cart-btn",
                                attrs: {
                                  "aria-label":
                                    _vm.i18n.listDetails.addListToCart
                                },
                                on: { click: _vm.addListToCart }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.i18n.listDetails.addListToCart)
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _vm.loadPdfGenerator
        ? _c(
            "vx-pdf-generator",
            {
              ref: "pdfModule",
              attrs: {
                name: "pdfversion",
                "has-sustainability-page": _vm.hasSustainabilityPage,
                pdfData: _vm.pdpListdata
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "details" }, slot: "details" },
                [
                  _vm._l(_vm.pdpListdata.wishlistEntries, function(product) {
                    return [
                      _c("div", { attrs: { id: product.product.code } }, [
                        _c("h5", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.replaceText(product.product.name)
                            )
                          }
                        }),
                        _vm._v(" "),
                        product.product.description
                          ? _c("h5", [_vm._v("Description:")])
                          : _vm._e(),
                        _vm._v(" "),
                        product.product.description
                          ? _c("p", {
                              staticStyle: { "font-size": "12px" },
                              domProps: {
                                innerHTML: _vm._s(product.product.description)
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      product.product.featureList.length
                        ? _c("h5", { staticClass: "pdf-feature-heading" }, [
                            _vm._v("Features and Benefits:")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      product.product.featureList
                        ? _c("div", { staticClass: "pdf-features" }, [
                            _c(
                              "ol",
                              {
                                staticClass: "features",
                                staticStyle: { "font-size": "12px" }
                              },
                              _vm._l(product.product.featureList, function(
                                feature
                              ) {
                                return _c("li", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.replaceText(feature))
                                  }
                                })
                              })
                            )
                          ])
                        : _vm._e()
                    ]
                  }),
                  _vm._v(" "),
                  _c("img", { staticClass: "pdf-itf" }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "pdf-itf1",
                    attrs: { src: _vm.abc }
                  })
                ],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f8d5870c", { render: render, staticRenderFns: staticRenderFns })
  }
}